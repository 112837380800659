@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#e7ZrTe0tQIo1 {width: 150px;}
#e7ZrTe0tQIo1 text {font-family: 'Comforter Brush', cursive;font-style: normal;}
#e7ZrTe0tQIo4_to {animation: e7ZrTe0tQIo4_to__to 3000ms linear infinite alternate forwards}@keyframes e7ZrTe0tQIo4_to__to { 0% {transform: translate(596.527249px,239.005663px)} 16.666667% {transform: translate(680.000001px,240.431016px)} 43.333333% {transform: translate(806.502729px,240.431016px)} 56.666667% {transform: translate(800.000001px,240.431016px)} 100% {transform: translate(800.000001px,240.431016px)}} #e7ZrTe0tQIo6 {animation: e7ZrTe0tQIo6_c_o 3000ms linear infinite alternate forwards}@keyframes e7ZrTe0tQIo6_c_o { 0% {opacity: 0} 36.666667% {opacity: 0.29} 43.333333% {opacity: 1} 50% {opacity: 0.28} 56.666667% {opacity: 1} 60% {opacity: 1} 63.333333% {opacity: 0} 66.666667% {opacity: 0} 70% {opacity: 1} 73.333333% {opacity: 1} 76.666667% {opacity: 1} 80% {opacity: 0} 83.333333% {opacity: 0} 86.666667% {opacity: 0} 90% {opacity: 1} 93.333333% {opacity: 1} 96.666667% {opacity: 1} 100% {opacity: 1}} #e7ZrTe0tQIo8 {animation: e7ZrTe0tQIo8_c_o 3000ms linear infinite alternate forwards}@keyframes e7ZrTe0tQIo8_c_o { 0% {opacity: 0} 16.666667% {opacity: 0} 43.333333% {opacity: 1} 100% {opacity: 1}} #e7ZrTe0tQIo10 {animation: e7ZrTe0tQIo10_c_o 3000ms linear infinite alternate forwards}@keyframes e7ZrTe0tQIo10_c_o { 0% {opacity: 0} 16.666667% {opacity: 1} 100% {opacity: 1}}
/* @font-face {
  font-family: 'e7ZrTe0tQIo1:::Concert One';
  font-style: normal;
  font-weight: 400;
  src: url() format('truetype');
} */
